.popupWrapper {
  z-index: 9999999;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .confirmation-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 450px;
    padding: 0 25px 25px;
    z-index: 1111;
    will-change: transform;
    font-size: 17px;

    .loaderWrapper {
      padding: 25px !important;
      display: flex;
      align-items: center;
      flex-direction: column;

      .animatedButton {
        background-color: #2dca73;
      }

      .loadMessage {
        padding: 15px;
      }
    }

    .popup-wrap {
      display: flex;
      flex-direction: column;
      min-width: 400px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      border-top: 0;
      overflow: hidden;
      will-change: transform;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      max-width: 360px;

      .popup-content-wrap {
        background-color: #fff;
        padding: 25px 25px 10px;
        text-align: center;
        font-size: 15px;
        line-height: 20px;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .popup-action-wrap {
        background-color: #fff;
        padding: 15px 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 16px;

        .popup-action-btn {
          min-width: 115px;
          align-items: center;
          justify-content: center;
          display: flex;
          height: 36px;
          padding: 0 10px;
          font-size: 14px;
          border-radius: 5px;
          border: 1px solid #c4c4c4;
          color: #565454;
          cursor: pointer;
          font-family: Graphik-Regular, Arial, sans-serif;
          line-height: 36px;
          margin: 0 5px;
        }

        .positive-btn {
          background-color: #3953fb;
          color: #fff;
        }
      }
    }
  }

  .confirmation-popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
    background: #000;
    opacity: 0.3;
  }
}
