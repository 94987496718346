@font-face {
  font-family: "Bungee";
  src: url("../fonts/Bungee-Regular.ttf"); /* IE9 Compat Modes */
  src: url("../fonts/Bungee-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Graphik-Regular";
  src: url("../fonts/Graphik-400-Regular.otf"); /* IE9 Compat Modes */
  src: url("../fonts/Graphik-400-Regular.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Graphik-Semibold";
  src: url("../fonts/Graphik-Semibold.otf"); /* IE9 Compat Modes */
  src: url("../fonts/Graphik-Semibold.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Graphik-Medium";
  src: url("../fonts/Graphik-Medium.otf"); /* IE9 Compat Modes */
  src: url("../fonts/Graphik-Medium.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Graphik-Bold";
  src: url("../fonts/Graphik-Bold.otf"); /* IE9 Compat Modes */
  src: url("../fonts/Graphik-Bold.otf") format("opentype");
  font-display: swap;
}
