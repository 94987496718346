.toggleSection {
    position: relative;
    color: $c-66;
    font-size: 14px;
    @include flex-direction(row);
    .labelText {
        position: relative;
        padding-left: 6px;
        padding-top: 3px;
        &.active {           
            color: $sg-blue;
            -webkit-font-smoothing: antialiased;
        }
    }
    .toggleCheckbox {
        position: relative;
        @include wt-ht(32px, 18px);
        overflow: hidden;
        border-radius: 100px;
        .inputCheckbox {
            position: relative;
            @include wt-ht(100%, 100%);
            padding: 0;
            margin: 0;
            opacity: 0;
            cursor: pointer;
            z-index: 3;
            &:checked + .knobs:before {
                left: 16px;
                background-color: white;
            }
            &:checked ~ .layer {
                background-color: #3ecb6f;
            }
        }
        &.loading {
            .knobs::before {
                animation: spin 1s linear infinite;
                border: 1.5px solid white;
                border-bottom-color: transparent;
                border-left-color: transparent;
                border-left: 1px;
                background-color: transparent;
            }
            .inputCheckbox:checked + .knobs:before {
                background-color: transparent;
            }
        }
    }
    .knobs {
        position: absolute;
        inset: 0;
        &::before {
            content: "";
            position: absolute;
            top: 2px;
            left: 3px;
            @include wt-ht(14px, 14px);
            color: white;
            font-size: 10px;
            font-weight: bold;
            text-align: center;
            line-height: 1;
            padding: 3px;
            z-index: 2;
            background-color: white;
            border-radius: 50%;
            transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
        }
    }
    .layer {
        position: absolute;
        inset: 0;
        width: 100%;
        transition: 0.3s ease all;
        z-index: 1;
        background-color: #ccc;
        border-radius: 100px;
    }
}

.toggleSectionLoader {
    position: relative;
    display: block;
    @include wt-ht(32px, 18px);
    border-radius: 20px;
}
