.settingsPopupWrapper {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .settingsPopupContainer {
    margin: auto;
    width: 500px;
    position: relative;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 20px;
    z-index: 2;

    .closeIcon {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 20px;
      cursor: pointer;
    }

    .settingsPopupHeader {
      position: relative;
      margin-bottom: 24px;
      span,
      i {
        font-weight: bold;
      }
    }
    .settingsPopupBody {
      position: relative;
      display: block;
      height: 100%;
    }
  }
}

.settingsPopupOverlay {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.53);
  cursor: pointer;
}
