.inputBoxWrapper {
  @include flex-center();
  margin-bottom: 30px;
  position: relative;
  @extend %relWidth;
  .errMsgText {
    position: absolute;
    bottom: -15px;
    font-size: 11px;
    left: 0;
    color: red;
  }
  .inputCont {
    @extend %relWidth;
    display: inline-block;
    background-color: $white;
    color: $black;

    .inputBox {
      line-height: 36px;
      font-size: 15px;
      will-change: font-size;
      width: 100%;
      -webkit-appearance: none;
      border: none;
      color: $sg-blue;
      border-bottom: 1px solid $c-dd;
      outline: none;
    }

    .inputLabel {
      color: $c-66;
      font-size: 13px;
      will-change: font-size;
      position: absolute;
      bottom: 12px;
      letter-spacing: 0.3px;
      left: 0px;
      transition: all 0.2s linear;
      .required {
        color: red;
        padding-left: 5px;
      }
    }

    &.readOnlyInput {
      .inputLabel {
        bottom: 36px;
        will-change: font-size;
        font-size: 11px;
        transition: all 0.2s linear;
      }

      .inputBox {
        border: 0px;
      }
    }

    .inputBox ~ .focusBorder {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      will-change: height;
      z-index: 99;
    }
    .inputBox ~ .focusBorder:before,
    .inputBox ~ .focusBorder:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: $sg-blue;
      transition: 0.4s;
      will-change: background-color;
    }
    .inputBox ~ .focusBorder:after {
      left: auto;
      right: 0;
    }
    .inputBox:focus ~ .focusBorder:before,
    .inputBox:focus ~ .focusBorder:after {
      width: 50%;
      transition: 0.4s;
    }
    .inputBox:focus ~ label,
    .inputBox.filled ~ label {
      bottom: 36px;
      font-size: 11px;
      transition: all 0.2s linear;
    }
  }
}

.standardInputWrapper {
  position: relative;
  .inputCont {
    width: 80%;

    .input-box {
      height: 35px;
      border-radius: 4px;
      background-color: #fff;
      border: solid 1px #cdccd5;
      color: #4c4761;
      font-size: 14px;
      padding: 0 10px;
      width: 100%;
    }
  }

  .errMsgText {
    font-size: 12px;
    color: $red-color;
    display: block;
    position: absolute;
    bottom: -20px;
  }
}
