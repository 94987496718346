.manageCustomerPage {
  @include pageClass;

  .layout {
    
    overflow-y: auto;
    @include bodyClass;

    .table {
      @include card();
    }

    .body {
      padding-bottom: 50px;
      overflow-y: auto;
      height: calc(100% - 100px);
    }

    .removeSEO
    {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 35vh;
      width: 50%;
    }

    .inputbox
    {
      display: flex;
      justify-content: space-between;
    }




    .buttonCont {
      .btn {
        margin: 10px 0px 10px 0px;
        @include secondaryButton;
      }
    }
  }

  /* The Modal (background) */
.modal {
  /*display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */

  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 40%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: black;
  float: right;
  font-size: 24px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: white;
  color: black;
}

.modal-body {
  padding: 2px 16px;
  margin-left: 30%;
  display: flex;
}

.modal-footer {
  padding: 2px 16px;
  background-color: white;
  color: black;
}

}
