.trialUserList {
  max-width: calc(100% - 16px);
  .dataCont {
    display: grid;
    grid-template-columns: 37% 5% 58%;
    padding: 10px;
    .label {
      font-size: 15px;
    }
    .value {
      font-size: 14px;
    }
  }

  .titleCont {
    padding: 15px 0px;
    border-bottom: 1px solid $light-border;
    margin-bottom: 20px;
    font-weight: 600;
    @include flex-center();
    justify-content: space-between;
  }

  .bodyCont {
    .subscriptionParentCont {
      @extend %cardBodyStyle;
    }
  }
  .tableContainer {
    border-collapse: collapse;
    width: 100%;
    th, td {
    border: 1px solid $light-border;
    padding: 8px;
    }
  }
  .noNewUser {
    font-size: 22px;
    color: orange;
    text-align: center;
  }
}
