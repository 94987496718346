*,
*::before,
*::after {
  box-sizing: border-box;
}

::placeholder {
  color: #7878788a;
  font-size: 14px;
}

html {
  font-family: "Graphik-Regular", sans-serif;
  line-height: 1.25;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
}

.scrollHidden {
  overflow: hidden;
}

body {
  margin: 0;
  font-weight: 400;
  text-align: left;
  background-color: #fff;
  height: 100%;
  overflow: hidden;

  #root,
  main {
    height: 100%;
    width: 100%;
  }
}

.animatedFast {
  transform-origin: top center;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  animation-duration: 400ms;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
}

.linear-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  height: 14px;
  border: none;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
