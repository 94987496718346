.forbiddenPage {
  font-family: "Bungee", cursive;
  @include flex-center();
  justify-content: center;
  height: 100%;

  .content {
    @include flex-center();
    justify-content: center;
    flex-direction: column;
  }

  a {
    color: #2aa7cc;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  svg {
    width: 25vw;
  }

  .lightblue {
    fill: #444;
  }

  .eye {
    cx: calc(115px + 30px * var(--mouse-x));
    cy: calc(50px + 30px * var(--mouse-y));
  }

  #eye-wrap {
    overflow: hidden;
  }

  .error-text {
    font-size: 120px;
  }

  .error-message {
    font-size: 16px;
    font-family: "Graphik-Regular", sans-serif;
  }
}
