// Font
$font-regular: "Graphik-Regular";
$font-medium: "Graphik-Medium";
$font-bold: "Graphik-Bold";
$font-semi-bold: "Graphik-Semibold";

$white: #fff !default;
$text-color: #585858 !default;
$text-medium: #787878 !default;
$light-border: #ddd !default;
$sg-blue: #3953fb !default;
$red-color: #ff0000 !default;
$text-dark: #666 !default;
$black: #000 !default;
$light-green: #2dca73 !default;

// COLOR CODE
$c-f5: #f5f5f5 !default;
$c-cc: #ccc !default;
$c-78: #787878 !default;
$c-dd: #ddd !default;
$c-a7: #a7a7a7 !default;
$c-d3: #d3d3d3 !default;
$c-dd: #ddd !default;
$c-66: #666 !default;
$bg-default: #f3f4f8 !default;
