.customerProfileDetails {
  display: block;
  position: relative;
  color: #666;
  column-gap: 30px;
  width: 80%;
  color: $text-dark;

  .tableInfo {
    .titleCont {
      padding: 15px 0px;
      border-bottom: 1px solid $light-border;
      margin-bottom: 20px;
      font-weight: 600;
      @include flex-center();
      justify-content: space-between;
    }
    .buttonWrapper {
      @include flex-center();
      gap: 12px;
    }

    .bodyCont {
      .subscriptionParentCont {
        @extend %cardBodyStyle;
      }
    }
  }
  .flexBetween {
    display: flex;
    div {
      &:first-child {
        margin-right: 16px;
      }
    }
  }

  .errMessageText {
    color: red;
  }

  .table {
    .dataCont {
      display: grid;
      grid-template-columns: 37% 5% 58%;
      padding: 10px;
      align-items: center;
      &.connectedInbox {
        .value {
          text-transform: none;
        }
      }
      .editWrapper {
        @include flex-center();
        position: relative;
        .standardInputWrapper {
          width: 80px;
          &.editSeats,
          &.editPricing {
            position: unset !important;
          }
          .inputCont {
            width: auto;
            .input-box {
              height: 25px;
            }
          }
        }

        &.editEmailInput .standardInputWrapper {
          width: 160px;
          width: unset;
        }

        @keyframes spinner {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        .loader {
          position: relative;
          height: 20px;
          width: 20px;
          display: inline-block;
          animation: spinner 5.4s infinite;
          margin-left: 8px;
        }

        .loader::after,
        .loader::before {
          content: "";
          background: white;
          position: absolute;
          display: inline-block;
          width: 100%;
          height: 100%;
          border-width: 2px;
          border-color: #333 #333 transparent transparent;
          border-style: solid;
          border-radius: 20px;
          box-sizing: border-box;
          top: 0;
          left: 0;
          animation: spinner 0.7s ease-in-out infinite;
        }

        .loader::after {
          animation: spinner 0.7s ease-in-out 0.1s infinite;
          background: transparent;
        }
        .editIcon,
        .checkIcon,
        .closeIcon {
          margin-left: 8px;
          cursor: pointer;
          font-size: 18px;
        }
        .editInputBox {
          width: 70px;
        }
      }

      &.email {
        .value {
          text-transform: unset;
        }
      }

      .label {
        font-size: 15px;
      }

      .value {
        font-size: 14px;
        text-transform: capitalize;
      }

      &.planStatus {
        #activate {
          margin-left: 20px;
        }
      }
    }
    .additionalSettings {
      width: fit-content;
      cursor: pointer;
      display: flex;
      padding: 10px;
      color: $sg-blue;

      .additionalSettingsLabel {
        font-size: 15px;
      }
      .additionalSettingsIcon {
        font-size: 18px;
        margin-left: 10px;
      }
    }
  }
}
.updateSubscriptionWrapper,
.changeSubscriptionWrapper {
  .dataContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 80%;
    margin-bottom: 16px;
    &:first-child {
      margin-top: 16px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    select {
      border: 1px solid #dcdcdc;
      background-color: transparent;
      outline: 0;
      width: 180px;
      border-radius: 4px;
      height: 24px;
    }
  }
  .errorMessage {
    color: red;
    font-size: 12px;
    position: absolute;
  }
  .submitButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    button {
      width: 150px;
    }
  }
  .standardInputWrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    .inputCont {
      width: 180px;
      input {
        height: 24px;
      }
    }
  }
  .createSubscriptionButton,
  .updateSubscriptionButton {
    margin-right: 16px;
  }
  .cancelButton {
    text-align: center;
    justify-content: center;
  }
}
