.discoverSettingSection {
    position: relative;
    .actionBtn {
        position: relative;
        padding: 10px;
        display: flex;
        width: fit-content;
        align-items: center;
        color: $sg-blue;
        cursor: pointer;
        font-size: 15px;
        border-radius: 10px;
        .label {
            position: relative;
            font-size: 15px;
        }
        i {
            margin-left: 10px;
            font-size: 18px;
        }
        &:hover {
            background: $c-f5;
            transition: all 0.3s ease;
        }
    }
    .popup {
        position: fixed;
        @include wt-ht(100%, 100%);
        inset: 0;
        @include flex-center;
        justify-content: center;
        z-index: 1;
        .popupBody {
            position: relative;
            min-width: 70%;
            height: 85vh;
            overflow: hidden;
            z-index: 2;
            background: white;
            border-radius: 5px;
            i.close {
                position: absolute;
                right: 10px;
                top: 10px;
                color: #666;
                cursor: pointer;
                border-radius: 35px;
                font-size: 20px;
                z-index: 10;
                &:hover {
                    color: #222;
                    transition: all 0.3s ease;
                    background-color: #f5f5f5;
                }
            }
            .popupCard {
                position: relative;
                height: calc(100% - 55px);
                overflow-y: scroll;
                padding: 0 25px 25px;
                h3 {
                    padding-top: 25px;
                    position: sticky;
                    top: 0;
                    background: white;
                    z-index: 5;
                    margin-top: 0;
                    padding-bottom: 10px;
                    border-bottom: 1px solid $c-dd;
                }
                .toggleSection {
                    padding: 10px 0;
                }
                .emailCreditLimitSection,
                .phoneCreditLimitSection,
                .bulkPurchaseSection {
                    position: relative;
                    h4 {
                        font-size: 16px;
                        margin: 0 0 10px;
                        font-weight: 500;
                    }
                }
                .phoneCreditLimitSection {
                    margin: 10px 0;
                }
                .inputContainer {
                    display: grid;
                    grid-template-columns: repeat(3, 31%);
                    justify-content: space-between;
                    padding-top: 15px;
                    border-bottom: 1px dashed #eee;
                    .inputBoxWrapper {
                        margin-bottom: 15px;
                    }
                }
                .bulkPurchaseSection {
                    padding-top: 20px;
                }
                .twoColLayout {
                    display: grid;
                    grid-template-columns: repeat(2, 48%);
                    justify-content: space-between;
                    .inputContainer{
                        border: none;
                    }
                }
            }
            .popupFooter {
                position: relative;
                padding-right: 30px;
                @include flex-center;
                justify-content: flex-end;
                height: 55px;
                border-top: 1px solid $c-dd;
            }
        }
        .bgOverlay {
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.53);
            cursor: pointer;
        }
    }
}
