.site-content {
  float: left;
  width: calc(100% - 60px);
  margin-left: 60px;
  color: #787878;
  height: 100%;

  &.headerNotificationShown {
    height: calc(100% - 50px);
    margin-top: 50px;
  }

  .commonPopup {
    width: 65%;
    height: auto;
    padding: 25px;
    font-family: "Graphik-Regular", Arial, sans-serif;
    position: fixed;
    z-index: 112;
    top: 50%;
    display: block;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 440px;
    border-radius: 5px;
    animation: modalSlide 0.75s;
    .commonPopupInnerCont {
      border-radius: 5px;
      overflow: hidden;

      .popupHeader {
        font-size: 21px;
        font-weight: 500;
        color: #fff;
        background: #2176b7;
        padding: 20px;
      }

      .popupBodyContainer {
        padding: 20px;
        background: #fff;

        .input-cont {
          padding: 10px 0;
          width: 100%;
          display: inline-block;

          input {
            color: #212420;
            font-size: 14px;
            padding: 5px;
            border-width: 0 0 1px;
            background: #fff;
            border-color: #eceff1;
            line-height: 25px;
            width: 80%;

            &:focus {
              border-color: #2080ed;
            }
          }
        }
      }
    }

    .popup-close {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;

      svg {
        fill: #fff;
      }
    }
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}
