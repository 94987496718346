%relBlock{
  display: block;
  position: relative;
}

%cardBodyStyle{
  position: relative;
  padding-bottom: 30px;

  &::after {
    content: "";
    width: 100%;
    position: absolute;
    bottom: 0;

    margin: 15px 0px;
    border-bottom: 1px solid $light-border;
  }

  &:last-child::after {
    display: none;
  }
}