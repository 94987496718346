.sideBar {
  position: fixed;
  padding: 0;
  margin: 0;
  width: 60px;
  background: #261d56;
  height: 100vh;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  user-select: none;
  a {
    display: inline-block;
    padding: 15px 0;
  }
  svg {
    width: 42px;
    height: 42px;
    fill: $white;
  }

  .menuList {
    @include list-style-space();
    @include flex-center();
    flex-direction: column;
    @include width-height(100%, 40px);

    a {
      padding: 10px;
      margin: 3px 0px;
      @include width-height(100%, 40px);
      text-decoration: none;
      @include flex-center();
      justify-content: center;
      cursor: pointer;
      color: $white;

      &.selected-path {
        -webkit-animation: sliderFromRightNav 0.5s;
        animation: sliderFromRightNav 0.5s;
        height: 36px;
        width: 100%;
        @include flex-center();
        justify-content: center;
        background: url("../../assets/svg/blue-oval.svg");
        background-position: 100%;
        background-repeat: no-repeat;
        background-size: 85% 36px;
        margin-right: -1px;
      }
    }
  }
}
