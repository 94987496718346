.common-toast-wrapper {
  //visibility: hidden;
  width: fit-content;
  will-change: transform;
  color: $white !important;
  text-align: center;
  border-radius: 5px;
  padding: 10px 16px;
  position: absolute;
  z-index: 99;
  font-size: 14px;
  left: 0;
  right: 0;
  margin: auto;
  top: 0px;
  box-shadow: 0px 6px 11.5px 0.5px rgba(59, 59, 59, 0.17);
  opacity: 0;
  transform: translate(0%, -100%);
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
  align-items: center;

  .messageCont {
    display: flex;

    .svgIcon {
      width: 16px;
      height: 16px;
      margin-right: 10px;

      svg {
        width: 16px;
        height: 16px;
      }

      &.crossIcon {
        svg {
          fill: #fff;
        }
      }

      &.tickIcon {
        svg {
          fill: #fff;
        }
      }

      &.infoIcon {
        i {
          font-size: 16px;
        }
      }
    }
  }
}

.show {
  top: 15px;
  opacity: 1;
  transform: translate(0%, 0%);
}
