.extendTrial {
  display: flex;
  align-items: center;
  .standardInputWrapper {
    .inputCont {
      width: 100% !important;
      .input-box {
        padding: 5px 10px;
        border-radius: 3px;
        border: 1px solid #ccc;
        color: #666;
        width: auto;
        &::placeholder {
          color: #666;
        }
      }
    }
    .errMsgText {
      white-space: nowrap;
    }
  }
  
  .btnSection {
    margin-left: 30px;
    button {
      padding: 8px 15px;
    }
  }
}
