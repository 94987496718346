.headerCont {
  @include flex-center;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid $light-border;
  padding-bottom: 30px;

  .leftSection {
    width: 80%;
    @include flex-center;
    justify-content: flex-start;
  }

  .rightSection {
    width: 20%;
    @include flex-center;
    justify-content: flex-end;
  }

  .titleCont {
    font-size: 1.5rem;
    padding: 30px 0px 10px 0px;
    font-weight: 600;
    color: $text-color;
  }
}
