.updateUserFeatureFlagWrapper {
  display: block;
  position: relative;
  .userFeatureFlagCont {
    max-height: 460px;
    overflow: auto;
    padding: 0 6px;
  }
  .dataContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 80%;
    margin-bottom: 16px;
    &:first-child {
      margin-top: 16px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    select {
      border: 1px solid #dcdcdc;
      background-color: transparent;
      outline: 0;
      width: 100px;
      border-radius: 4px;
      height: 24px;
    }
  }
  .submitButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    button {
      width: 150px;
    }
  }
  .dialerCredits,
  .noOfInboxesEligible,
  .noOfSequencesEligible,
  .noOfTemplatesEligible,
  .emailSendLimit,
  .noOfContactsEligible,
  .customFieldsCount,
  .emailValidationCredits,
  .warmingAccountsEligible,
  .noOfLiAccountsEligible {
    position: relative;
    display: flex;
    justify-content: flex-end;
    .inputCont {
      width: 100px;
      input {
        height: 24px;
      }
    }
  }
}
