::-webkit-scrollbar-track {
  background-color: #fcfcfc;
  border-radius: 4px;
}

::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}

::-webkit-scrollbar-thumb {
  background-color: #ddd;
}

::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px;
}