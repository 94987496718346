.checkboxWrapper {
    @include flex-center();
    user-select: none;
    .commonFilledCheckBox {
        position: relative;
        @include wt-ht(15px, 15px);
        border: 1px solid $c-cc;
        -webkit-perspective: 18px;
        perspective: 18px;
        display: block;
        border-radius: 3px;
        transform: translateZ(0);
        overflow: hidden;
        cursor: pointer;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        &:hover {
            border-color: $sg-blue;
        }
        .checkBoxCard {
            -webkit-transition: all 0.4s ease;
            transition: all 0.4s ease;
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            position: relative;
            @include wt-ht(13px, 13px);
            .checkSvgIcon,
            .emptyCheckBox {
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                position: absolute;
                top: 0;
                left: 0;
                @include wt-ht(13px, 13px);
                border-radius: 3px;
                svg {
                    margin-top: 1px;
                    fill: none;
                    @include wt-ht(11px, 11px);
                    path {
                        stroke: $sg-blue;
                        stroke-width: 2.5;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                    }
                }
            }
            .emptyCheckBox {
                background-color: $white;
                z-index: 1;
            }
            .checkSvgIcon {
                -webkit-transform: rotateY(180deg);
                transform: rotateY(180deg);
                background: #eaedff;
                text-align: center;
                color: $sg-blue;
                width: 13px;
                height: 13px;
                line-height: 15px;
                box-shadow: 0 0 0 1px $sg-blue;
            }
        }
        &.checked {
            border-color: $sg-blue;
            .checkBoxCard {
                -webkit-transform: rotateY(180deg);
                transform: rotateY(180deg);
            }
        }
    }
    label {
        padding-left: 7px;
        font-size: 14px;
        color: $c-78;
        word-break: break-word;
        cursor: pointer;
        line-height: 14px;
    }
}
