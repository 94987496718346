.wipeAllDataCard {
  .wipeAllDataItemList {
    @extend %cardBodyStyle;
    &:not(:last-child) {
      border-bottom: 1px solid $light-border;
    }
  }
  .jobStatus {
    margin: 0 auto;
    color:$sg-blue;
    font-size: 16px;
    padding: 5px 20px;
    border-radius: 3px;
    width: fit-content;
  }
}
