.secondaryButton {
  @include secondaryButton();
}

.primaryButton.animatedButton {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 10px;
  font-weight: 500;
  color: $white;
  background: $sg-blue;
  font-size: 13px;
  border-radius: 5px;
  transition: all 0.3s ease;

  &.loading {
    position: relative;
    cursor: not-allowed;
    transition: all 0.3s ease;
    border-radius: 45px;
    width: 40px !important;
    height: 40px !important;

    .load-text {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 5px;
      left: 5px;
      color: #fff;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      line-height: 1;
      padding: 5px 5px;
      z-index: 2;
      border-radius: 50%;
      transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
      animation: spin 1s linear infinite;
      border: 1.5px solid #ffffff;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-left: 1px;
      background-color: transparent;
    }
  }
}
