.dateFilterSection {
  max-width: 70%;
  .customerHeader {
    @include flex-center;
    border-bottom: 1px solid $light-border;
    .titleCont {
      font-size: 18px;
      padding: 30px 0px 10px 0px;
      color: $text-color;
    }
  }
  .filterWrapper {
    display: flex;
  }
  .fromDateContainer,
  .toDateContainer {
    padding: 35px 0px;
    position: relative;
    border-bottom: 1px solid $light-border;
    width: 50%;
    .dateHeader {
      display: flex;
      flex-direction: column;
    }
  }

  .buttonCont {
    margin: 15px 0px;
    @include flex-center;
    justify-content: flex-end;
  }
}
.createTrialWrapper {
  max-width: 100%;
  .filterWrapper {
    @include flex-center();
    .fromDateContainer {
      margin-right: 8px;
    }
    .inputCont {
      width: 100%;
    }
  }
  .buttonCont {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
}
