@mixin list-style-space {
  padding: 0;
  margin: 0;
  list-style: none;
}

@mixin wt-ht($wt: 100%, $ht: 100%) {
  width: $wt;
  height: $ht;
}

@mixin center-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #6c6f73;
}

@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin flex-direction($direction) {
  @include flex-center();
  flex-direction: $direction;
}

@mixin width-height($width, $height) {
  width: $width;
  height: $height;
}

@mixin ellipsis() {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin pageClass {
  width: 100%;
  height: 100%;
  border-color: #f3f4f8;
  font-family: "Graphik-Regular", sans-serif;
  padding: 10px;
}

@mixin bodyClass {
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  max-width: 1200px;
}

@mixin secondaryButton {
  @include flex-center;
  border: 1px solid $sg-blue;
  background-color: #fff;
  border-radius: 5px;
  padding: 0 10px;
  cursor: pointer;
  height: 30px;
  margin-right: 15px;
  font-size: 13px;
  color: $sg-blue;
  outline: none;

  &:hover {
    background: $sg-blue;
    color: #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}

@mixin card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 15px;
  margin: 15px 0px;
}

@mixin text-align($property) {
  text-align: $property;
}

@mixin badge($textColor, $bg, $pad, $fs, $bor-rad) {
  color: $textColor;
  background: $bg;
  padding: $pad;
  font-size: $fs;
  border-radius: $bor-rad;
  border: 1px solid $bg;
}

@mixin highLightText($color, $fs) {
  color: $color;
  font-size: $fs;
}

%relWidth {
  position: relative;
  width: 100%;
}
