/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v47/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
  font-display: swap;
}

/* fallback */
@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v8/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2)
    format("woff2");
  font-display: swap;
}

/* fallback */
@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsround/v8/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmP.woff2)
    format("woff2");
  font-display: swap;
}

/* fallback */
@font-face {
  font-family: "Material Icons Sharp";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconssharp/v9/oPWQ_lt5nv4pWNJpghLP75WiFR4kLh3kvmvR.woff2)
    format("woff2");
  font-display: swap;
}

/* fallback */
@font-face {
  font-family: "Material Icons Two Tone";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconstwotone/v7/hESh6WRmNCxEqUmNyh3JDeGxjVVyMg4tHGctNCu0.woff2)
    format("woff2");
  font-display: swap;
}
@mixin mdicon-type($args...) {
  @debug keywords($args);
  @each $class-name, $prop-value in keywords($args) {
    .#{$class-name} {
      font-family: $prop-value;
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
    }
  }
}
@include mdicon-type(
  $material-icons: "Material Icons",
  $material-icons-outlined: "Material Icons Outlined",
  $material-icons-round: "Material Icons Round",
  $material-icons-sharp: "Material Icons Sharp",
  $material-icons-two-tone: "Material Icons Two Tone"
);
