.customerEmailCont {
  position: relative;
  display: block;
  padding: 30px 0;
  width: 100%;
  .contentBox {
    position: relative;
    display: block;
    border-radius: 5px;
    padding: 30px;
    border: 1px solid #ddd;
    max-width: 600px;
    h3 {
      position: relative;
      display: block;
      margin: 0;
      font-weight: 500;
      color: #444;
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
    }
    .emailInputCont {
      position: relative;
      display: block;
      padding-top: 30px;
    }
    .buttonCont {
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;
      button {
        letter-spacing: 0.3px;
        font-size: 14px;
      }
    }
  }
}
